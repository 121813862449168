const AliyunCaptcha = function (prop) {
  this.initCapUrl = '/app.php/api/aliyun_captcha'
  this.captcha = null
  this.verify_id = null
  this.pageData = prop.pageData ? prop.pageData : {}
  this.getInstance = this.getInstance.bind(this)
  const defalutCaptchaData = {
    prefix: 'a4g08f',
    element: '#captcha-element',
    button: '#aliyun-button',
    getInstance: this.getInstance,
    slideStyle: {
      width: 360,
      height: 40,
    },
    language: 'cn',
  }
  this.AliyunCaptchaData = Object.assign(defalutCaptchaData, prop.AliyunCaptchaData)
  this.initAliyun()

}

AliyunCaptcha.prototype.initCap = function () {
  const self = this
  $.ajax({
    type: 'get',
    url: self.initCapUrl + `?system_scene_id=${self.pageData.system_scene_id}`,
    async: false,
    success: function ({ data, status }) {
      if (status) {
        self.AliyunCaptchaData.mode = data.mode
        self.AliyunCaptchaData.SceneId = data.scene_id
        self.verify_id = data.verify_id
      }
    }
  })
}

AliyunCaptcha.prototype.initAliyun = function () {
  this.initCap()
  initAliyunCaptcha(this.AliyunCaptchaData)
}

AliyunCaptcha.prototype.getInstance = function (instance) {
  this.captcha = instance;
}

AliyunCaptcha.prototype.startTime = function (time) {
  if (time == 0) {
    this.pageData.btnDom.removeAttr("disabled");
    this.pageData.btnDom.html(this.pageData.btnText);
    time = 60;
  } else {
    this.pageData.btnDom.attr("disabled", true);
    this.pageData.btnDom.html(time + "S");
    time--;
    this.setCookie(time)
    setTimeout(() => {
      this.startTime(time);
    }, 1000);
  }
}

AliyunCaptcha.prototype.setCookie = function (second) {
  var exdate = new Date();
  exdate.setTime(exdate.getTime() + second * 1000);
  document.cookie = this.pageData.cookieKey + "=" + escape(second) + (second == null ? "" : ";expires=" + exdate.toGMTString());
}

window.AliyunCaptcha = AliyunCaptcha;